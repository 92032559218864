import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./SideBar.css";

function SideBar() {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div className="sidebar">
      <div className="sidebar__profile">
        <img
          src={currentUser.avatar}
          alt="Your Avatar"
          className="sidebar__profile-avatar"
        />
        <p className="sidebar__profile-name">{currentUser.name}</p>
      </div>
    </div>
  );
}

export default SideBar;
