import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./ItemCard.css";
import like from "../../images/like.svg";
import unlike from "../../images/unlike.svg";
function ItemCard({ item, onCardSelect, onCardLike }) {
    const { currentUser } = useContext(CurrentUserContext);

    const isLiked = item.likes.some((id) => id === currentUser?._id);

    return (
        <div className="card">
            <div>
                <img
                    src={item.imageUrl}
                    alt={item.name}
                    onClick={() => onCardSelect(item)}
                    className="card__image"
                />
            </div>
            <button className="card__name" onClick={() => onCardSelect(item)}>
                {item.name}
            </button>
            {!isLiked ? (
                <img
                    className="card__like"
                    onClick={() => onCardLike(item._id, isLiked)}
                    src={like}
                    alt=""
                />
            ) : (
                <img
                    className="card__like"
                    onClick={() => onCardLike(item._id, isLiked)}
                    src={unlike}
                    alt=""
                />
            )}
        </div>
    );
}

export default ItemCard;
