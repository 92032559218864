import "./ToggleSwitch.css";
import { useContext } from "react";
import CurrentTemperatureUnitContext from "../../contexts/CurrentTemperatureUnitContext";

function ToggleSwitch() {
    const { tempUnit, handleToggleSwitchChange } = useContext(
        CurrentTemperatureUnitContext,
    );

    return (
        <label className="switch">
            <input
                type="checkbox"
                className="switch__input"
                onChange={handleToggleSwitchChange}
            />
            <span
                className={
                    tempUnit === "F"
                        ? "switch__slider switch__slider-C"
                        : "switch__slider switch__slider-F"
                }
            ></span>
            <p className={`switch__temp-F ${tempUnit === "F" && "switch__active"}`}>
                F
            </p>
            <p className={`switch__temp-C ${tempUnit === "C" && "switch__active"}`}>
                C
            </p>
        </label>
    );
}

export default ToggleSwitch;
