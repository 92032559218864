import "./ModalWithForm.css";

function ModalWithForm({
  children,
  buttonText,
  title,
  onClose,
  name,
  onSubmit,
}) {
  return (
    <div className={`modal modal__type_${name}`}>
      <div className="modal__content">
        <img
          src="../../images/close.svg"
          alt="Close Button"
          className="modal__close"
          onClick={onClose}
        />
        <h3 className="modal__heading">{title}</h3>
        <form onSubmit={onSubmit}>
          {children}
          <button className="modal__button" type="submit">
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ModalWithForm;
