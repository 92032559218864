import { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

function LoginModal({ handlerFunc, onClose, isOpen }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isOpen) {
      setFormData({
        email: "",
        password: "",
      });
    }
  }, [isOpen]);

  function handleSubmit(e) {
    e.preventDefault();
    handlerFunc(formData);
  }
  const onChange = (e) => {
    setFormData((prev) => {
      const helper = { ...prev };
      helper[`${e.target.name}`] = e.target.value;
      return helper;
    });
  };

  return (
    <ModalWithForm
      title="Login"
      name="signin"
      buttonText="Login"
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <div className="modal__inputs">
        <label className="modal__heading">
          Email *
          <input
            onChange={onChange}
            value={formData.email}
            className="modal__input"
            type="text"
            name="email"
            placeholder="Email"
            minLength="1"
            maxLength="30"
          />
        </label>
        <label className="modal__heading">
          Password *
          <input
            onChange={onChange}
            value={formData.password}
            className="modal__input"
            type="password"
            name="password"
            placeholder="Password"
            minLength="1"
            maxLength="30"
          />
        </label>
      </div>
    </ModalWithForm>
  );
}

export default LoginModal;
