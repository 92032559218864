import ModalWithForm from "../ModalWithForm/ModalWithForm";
import { useState, useEffect } from "react";

function AddItemModal({ isOpen, onAddItem, onClose }) {
    const [formData, setFormData] = useState({
        name: "",
        imageUrl: "",
        weather: "",
    });

    useEffect(() => {
        if (isOpen) {
            setFormData({
                name: "",
                imageUrl: "",
                weather: "",
            });
        }
    }, [isOpen]);

    function handleSubmit(e) {
        e.preventDefault();
        onAddItem(formData);
    }
    const onChange = (e) => {
        setFormData((prev) => {
            let helper = { ...prev };
            helper[`${e.target.name}`] = e.target.value;
            return helper;
        });
    };
    return (
        <ModalWithForm
            title="New Garment"
            buttonText="Add garment"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <div className="modal__inputs">
                <label className="modal__heading">
                    Name
                    <input
                        onChange={onChange}
                        value={formData.name}
                        className="modal__input"
                        type="text"
                        name="name"
                        placeholder="Name"
                        minLength="1"
                        maxLength="30"
                    />
                </label>
                <label className="modal__heading">
                    Image
                    <input
                        onChange={onChange}
                        value={formData.imageUrl}
                        className="modal__input"
                        type="text"
                        name="imageUrl"
                        placeholder="Image URL"
                        minLength="1"
                        maxLength="1000"
                    />
                </label>
            </div>
            <p className="modal__heading">Select the weather type:</p>
            <div className="modal__radios">
                <label id="hot" className="modal__radio">
                    <input
                        onChange={onChange}
                        className="modal__radio-input"
                        name="weather"
                        type="radio"
                        id="hot"
                        checked={formData.weather === "hot"}
                        value="hot"
                    />
                    Hot
                </label>
                <label id="warm" className="modal__radio">
                    <input
                        onChange={onChange}
                        className="modal__radio-input"
                        name="weather"
                        type="radio"
                        id="warm"
                        checked={formData.weather === "warm"}
                        value="warm"
                    />
                    Warm
                </label>
                <label id="cold" className="modal__radio">
                    <input
                        onChange={onChange}
                        className="modal__radio-input"
                        name="weather"
                        type="radio"
                        id="cold"
                        checked={formData.weather === "cold"}
                        value="cold"
                    />
                    Cold
                </label>
            </div>
        </ModalWithForm>
    );
}

export default AddItemModal;
