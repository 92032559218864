import { useEffect, useState } from "react";
import ModalWithForm from "../ModalWithForm/ModalWithForm";

function SignUpModal({ isOpen, onClose, handlerFunc }) {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        name: "",
        avatar: "",
    });

    useEffect(() => {
        if (isOpen) {
            setFormData({
                email: "",
                password: "",
                name: "",
                avatar: "",
            });
        }
    }, [isOpen]);

    function handleSubmit(e) {
        e.preventDefault();
        handlerFunc(formData);
    }
    const onChange = (e) => {
        setFormData((prev) => {
            const helper = { ...prev };
            helper[`${e.target.name}`] = e.target.value;
            return helper;
        });
    };

    return (
        <ModalWithForm
            title="Sign Up"
            buttonText="Sign Up"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <div className="modal__inputs">
                <label className="modal__heading">
                    Email *
                    <input
                        onChange={onChange}
                        value={formData.email}
                        className="modal__input"
                        type="text"
                        name="email"
                        placeholder="Email"
                        minLength="1"
                        maxLength="30"
                    />
                </label>
                <label className="modal__heading">
                    Password *
                    <input
                        onChange={onChange}
                        value={formData.password}
                        className="modal__input"
                        type="password"
                        name="password"
                        placeholder="Password"
                        minLength="1"
                        maxLength="30"
                    />
                </label>
                <label className="modal__heading">
                    Name *
                    <input
                        onChange={onChange}
                        value={formData.name}
                        className="modal__input"
                        type="text"
                        name="name"
                        placeholder="Name"
                        minLength="1"
                        maxLength="30"
                    />
                </label>
                <label className="modal__heading">
                    Avatar URL *
                    <input
                        onChange={onChange}
                        value={formData.avatar}
                        className="modal__input"
                        type="text"
                        name="avatar"
                        placeholder="Avatar URL"
                        minLength="1"
                        maxLength="1000"
                    />
                </label>
            </div>
        </ModalWithForm>
    );
}

export default SignUpModal;
