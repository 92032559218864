import { useContext } from "react";
import CurrentTemperatureUnitContext from "../../contexts/CurrentTemperatureUnitContext";
import "./WeatherCard.css";

function getWeather(bool) {
  if (bool === true) {
    return "day";
  } else return "night";
}

function WeatherCard({ isDay, weatherType, weatherTemp, weatherTempStr }) {
  const time = getWeather(isDay);
  const tempUnit = useContext(
    CurrentTemperatureUnitContext,
  ).currentTemperatureUnit;
  return (
    <section className="weather">
      <p className="weather__info">
        {weatherTemp}°{tempUnit}
      </p>
      <img
        className="weather__bg"
        src={`/images/${time}/${weatherType}.svg`}
        alt={`It's a ${weatherTempStr()} ${time}. It's ${weatherType}`}
      />
    </section>
  );
}
export default WeatherCard;
