import * as api from "../../utils/api";
import Header from "../Header/Header.jsx";
import Profile from "../Profile/Profile.jsx";
import Footer from "../Footer/Footer";
import AddItemModal from "../AddItemModal/AddItemModal.jsx";
import ItemModal from "../ItemModal/ItemModal.jsx";
import Main from "../Main/Main.jsx";
import { useEffect, useState } from "react";
import { getForecastWeather, parseWeatherData } from "../../utils/weatherApi";
import CurrentTemperatureUnitContext from "../../contexts/CurrentTemperatureUnitContext.js";
import { Route, Switch } from "react-router-dom";
import SignUpModal from "../SignUpModal/SignUpModal";
import {
  createUser,
  getCurrentUser,
  modifyUserData,
  signIn,
} from "../../utils/auth";
import LoginModal from "../LoginModal/LoginModal";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import ProfileEditModal from "../EditProfileModal/ProfileModal";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [weatherTemp, setWeatherTemp] = useState(70);
  const [location, setLocation] = useState("New York, US");
  const [weatherType, setWeatherType] = useState("sunny");
  const [currentTemperatureUnit, setCurrentTempUnit] = useState("C");

  const [weatherIsDay, setWeatherIsDay] = useState(true);

  const [cardsArr, setCardsArr] = useState([]);

  const [activeModal, setActiveModal] = useState("");
  const [currentCard, setCurrentCard] = useState({});

  const [weatherTempC, setWeatherTempC] = useState(0);
  const [weatherTempF, setWeatherTempF] = useState(0);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  function handleAddItemOpen() {
    setActiveModal("addItem");
  }
  function handleSignInOpen() {
    setActiveModal("signIn");
  }
  function handleSignUpOpen() {
    setActiveModal("signUp");
  }
  function handleEditProfileOpen() {
    setActiveModal("editProfile");
  }
  async function handleEditProfileSubmit(data) {
    try {
      await modifyUserData(data);
      handleModalClose();
      setCurrentUser({
        ...currentUser,
        name: data.name,
        avatar: data.avatar,
      });
    } catch (e) {
      console.log(e.message);
    }
  }
  function handleAddItemSubmit(item) {
    api
      .addItem(item)
      .then((item) => {
        setCardsArr([item, ...cardsArr]);
      })
      .then(() => {
        handleModalClose();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async function handleDeleteItem(id) {
    try {
      await api.deleteItem(id);
      setCardsArr(cardsArr.filter((card) => card._id !== id));
      handleModalClose();
    } catch (e) {
      console.error(e);
    }
  }

  function handleLogout() {
    localStorage.removeItem("jwt");
    setCurrentUser();
    setIsLoggedIn(false);
  }

  function handleModalClose() {
    setActiveModal("");
  }

  function handleCardSelect(card) {
    setCurrentCard(card);
    setActiveModal("item");
  }

  const handleToggleSwitchChange = () => {
    if (currentTemperatureUnit === "C") {
      setCurrentTempUnit("F");
      setWeatherTemp(weatherTempF);
    } else if (currentTemperatureUnit === "F") {
      setCurrentTempUnit("C");
      setWeatherTemp(weatherTempC);
    }
  };
  async function handleSignUp(data) {
    if (isLoggedIn) return;
    try {
      await createUser(data);
      await handleLogin(data);
    } catch (e) {
      console.error(e);
    }
  }

  async function handleLogin(data) {
    if (isLoggedIn) return;
    try {
      await signIn(data);
      const user = await getCurrentUser();
      setCurrentUser(user);
      setIsLoggedIn(true);
      handleModalClose();
    } catch (e) {
      console.error(e);
    }
  }
  //handle esc close
  useEffect(() => {
    if (!activeModal) return;

    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        handleModalClose();
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [activeModal]);

  function handleCardLike(id, isLiked) {
    const token = localStorage.getItem("jwt");
    // Check if this card is not currently liked
    !isLiked
      ? // if so, send a request to add the user's id to the card's likes array
        api
          // the first argument is the card's id
          .likeItem(id, token)
          .then((updatedCard) => {
            setCardsArr((cards) =>
              cards.map((item) => (item._id === id ? updatedCard : item)),
            );
          })
          .catch((err) => console.error(err))
      : // if not, send a request to remove the user's id from the card's likes array
        api
          // the first argument is the card's id
          .unlikeItem(id, token)
          .then((updatedCard) => {
            setCardsArr((cards) =>
              cards.map((item) => (item._id === id ? updatedCard : item)),
            );
          })
          .catch((err) => console.error(err));
  }
  async function getUserData() {
    if (localStorage.getItem("jwt")) {
      try {
        const userData = await getCurrentUser();
        if (userData.message) {
          throw new Error();
        }
        setCurrentUser(userData);
        setIsLoggedIn(true);
      } catch (e) {
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }

  function getCards() {
    api
      .getItems()
      .then((data) => {
        setCardsArr(data);
      })
      .catch((err) => {
        return console.error(err);
      });
  }
  //API
  useEffect(() => {
    getUserData();

    getForecastWeather()
      .then((data) => {
        const weatherData = parseWeatherData(data);
        setLocation(weatherData.location);
        setWeatherType(weatherData.weatherType);
        if (currentTemperatureUnit === "F") {
          setWeatherTemp(weatherData.temperatureF);
        } else {
          setWeatherTemp(weatherData.temperatureC);
        }
        setWeatherTempC(weatherData.temperatureC);
        setWeatherTempF(weatherData.temperatureF);
        setWeatherIsDay(weatherData.isDay);
      })
      .catch((err) => {
        return console.error(err);
      });
    getCards();
    setIsLoaded(true);
  }, [currentTemperatureUnit]);

  if (isLoaded === false) {
    return <div></div>;
  }

  return (
    <CurrentUserContext.Provider value={{ isLoggedIn, currentUser }}>
      <CurrentTemperatureUnitContext.Provider
        value={{
          currentTemperatureUnit,
          handleToggleSwitchChange,
          handleCardSelect,
          weatherTemp,
          handleDeleteItem,
          handleModalClose,
        }}
      >
        <Header
          modalHandler={handleAddItemOpen}
          location={location}
          handleSignIn={handleSignInOpen}
          handleSignUp={handleSignUpOpen}
          handleLogout={handleLogout}
          handleEditProfile={handleEditProfileOpen}
        />
        <Switch>
          <Route exact path="/">
            <Main
              onCardLike={handleCardLike}
              isDay={weatherIsDay}
              weatherType={weatherType}
              weatherTemp={weatherTemp}
              onCardSelect={handleCardSelect}
              cards={cardsArr}
              currentTemperatureUnit={currentTemperatureUnit}
              handleToggleSwitchChange={handleToggleSwitchChange}
            />
          </Route>
          <Route path="/profile">
            <Profile
              addCard={handleAddItemOpen}
              cards={cardsArr}
              onCardLike={handleCardLike}
              onCardSelect={handleCardSelect}
              getUserData={getUserData}
              getCards={getCards}
            />
          </Route>
        </Switch>
        <Footer />
        {activeModal === "addItem" && (
          <AddItemModal
            onClose={handleModalClose}
            onAddItem={handleAddItemSubmit}
          />
        )}
        {activeModal === "signUp" && (
          <SignUpModal onClose={handleModalClose} handlerFunc={handleSignUp} />
        )}
        {activeModal === "signIn" && (
          <LoginModal onClose={handleModalClose} handlerFunc={handleLogin} />
        )}
        {activeModal === "item" && (
          <ItemModal
            selectedCard={currentCard}
            onClose={handleModalClose}
            onDelete={handleDeleteItem}
          />
        )}
        {activeModal === "editProfile" && (
          <ProfileEditModal
            onClose={handleModalClose}
            onSubmit={handleEditProfileSubmit}
          />
        )}
      </CurrentTemperatureUnitContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default App;
