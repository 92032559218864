import "./ItemModal.css";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";

function ItemModal({ selectedCard, onClose, onDelete }) {
    function handleDelete() {
        onDelete(selectedCard._id);
    }
    const { currentUser } = useContext(CurrentUserContext);
    const isOwn = selectedCard.owner === currentUser._id;

    return (
        <div className="itemModal">
            <div className="itemModal__content">
                <img
                    src="../../images/modalClose.svg"
                    alt="Close Modal"
                    className="itemModal__close"
                    onClick={onClose}
                ></img>
                <img
                    className="itemModal__image"
                    src={selectedCard.imageUrl}
                    alt={selectedCard.name}
                />
                <div className="itemModal__info">
                    <div className="itemModal__info-line">
                        {isOwn && (
                            <>
                                <p className="itemModal__text">{selectedCard.name}</p>
                                <p
                                    onClick={handleDelete}
                                    className="itemModal__text itemModal__text-delete"
                                >
                                    Delete Item
                                </p>
                            </>
                        )}
                    </div>
                    <p className="itemModal__text">Weather: {selectedCard.weather}</p>
                </div>
            </div>
        </div>
    );
}
export default ItemModal;
