import "./Profile.css";
import ClothesSection from "../ClothesSection/ClothesSection";
import SideBar from "../SideBar/SideBar";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";

function Profile({ addCard, cards, onCardSelect, onCardLike }) {
    const { currentUser } = useContext(CurrentUserContext);

    if (currentUser === undefined) {
        return (
            <div>
                <p className="profile__loading">Loading...</p>
            </div>
        );
    }

    // eslint-disable-next-line
    const userCards = cards.filter((card) => {
        if (card.owner === currentUser._id) {
            return card;
        }
    });

    return (
        <div className="profile">
            <SideBar />
            <div className="profile__cards">
                <p className="profile__heading">
                    Your Items
                    <span onClick={addCard} className="profile__add">
                        + Add new
                    </span>
                </p>
                <ClothesSection
                    cards={userCards}
                    onCardLike={onCardLike}
                    onCardSelect={onCardSelect}
                />
            </div>
        </div>
    );
}

export default Profile;
