import "./Main.css";
import ClothesSection from "../ClothesSection/ClothesSection";
import WeatherCard from "../WeatherCard/WeatherCard";
import CurrentTemperatureUnitContext from "../../contexts/CurrentTemperatureUnitContext";

function Main({
    currentTemperatureUnit,
    weatherTemp,
    weatherType,
    onCardSelect,
    cards,
    isDay,
    onCardLike,
    handleToggleSwitchChange,
}) {
    const weatherTempStr = () => {
        if (currentTemperatureUnit === "F") {
            if (weatherTemp >= 86) {
                return "hot";
            } else if (weatherTemp >= 66 && weatherTemp <= 85) {
                return "warm";
            } else if (weatherTemp <= 65) {
                return "cold";
            }
        }
        if (currentTemperatureUnit === "C") {
            if (weatherTemp >= 30) {
                return "hot";
            } else if (weatherTemp >= 19 && weatherTemp <= 29) {
                return "warm";
            } else if (weatherTemp <= 18) {
                return "cold";
            }
        }
    };
    const filteredCards = cards.filter((item) => {
        return item.weather.toLowerCase() === weatherTempStr();
    });
    return (
        <CurrentTemperatureUnitContext.Provider
            value={{ currentTemperatureUnit, handleToggleSwitchChange }}
        >
            <main>
                <WeatherCard
                    isDay={isDay}
                    weatherType={weatherType}
                    weatherTemp={weatherTemp}
                    weatherTempStr={weatherTempStr}
                />
                <ClothesSection
                    weatherTemp={weatherTemp}
                    onCardSelect={onCardSelect}
                    cards={filteredCards}
                    onCardLike={onCardLike}
                />
            </main>
        </CurrentTemperatureUnitContext.Provider>
    );
}
export default Main;
