import ModalWithForm from "../ModalWithForm/ModalWithForm";
import "../ModalWithForm/ModalWithForm.css";
import { useContext, useEffect, useState } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";

export default function ProfileEditModal({ onClose, onSubmit }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [formData, setFormData] = useState({
    name: currentUser.name,
    avatar: currentUser.avatar,
  });
  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }
  return (
    <ModalWithForm
      title="Edit Profile"
      onSubmit={(e) => handleSubmit(e)}
      onClose={onClose}
      buttonText="Submit"
    >
      <div>
        <label className="modal__heading" htmlFor="EditProfileNameInput">
          Name
        </label>
        <input
          type="text"
          id="EditProfileNameInput"
          className="modal__input"
          name="name"
          value={formData.name}
          onInput={(e) => {
            setFormData({
              ...formData,
              name: e.target.value,
            });
          }}
        />
      </div>
      <div>
        <label className="modal__heading" htmlFor="EditProfileAvatarInput">
          Avatar
        </label>
        <input
          type="text"
          className="modal__input"
          value={formData.avatar}
          onInput={(e) => {
            setFormData({
              ...formData,
              avatar: e.target.value,
            });
          }}
        />
      </div>
    </ModalWithForm>
  );
}
