import { baseUrl } from "./api";

export async function createUser(data) {
  const { name, avatar, email, password } = data;

  await fetch(`${baseUrl}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, avatar, email, password }),
  });
}

export async function signIn(inputData) {
  const { email, password } = inputData;

  try {
    const res = await fetch(`${baseUrl}/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await res.json();

    return localStorage.setItem("jwt", data.token);
  } catch (e) {
    console.log(e);
  }
}
export async function checkToken() {
  const localToken = localStorage.getItem("jwt");
  if (localToken == null) {
    return false;
  }
  const res = await fetch(`${baseUrl}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localToken}`,
    },
  });
  if (localToken === res.token) {
    return true;
  }
  return false;
}

export async function getCurrentUser() {
  const localToken = localStorage.getItem("jwt");
  if (!checkToken()) return;
  const res = await fetch(`${baseUrl}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localToken}`,
    },
  });
  return res.json();
}

export async function modifyUserData(data) {
  const { name, avatar } = data;
  const localToken = localStorage.getItem("jwt");
  if (!checkToken()) return;
  const res = await fetch(`${baseUrl}/users/me`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localToken}`,
    },
    body: JSON.stringify({
      name,
      avatar,
    }),
  });
  return res.json();
}
