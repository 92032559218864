import * as api from "./api.js";
import apiKey from "./secret.js";
//Stary Oskol, RU

//const latitude = 51.298038;

//const longitude = 37.833202;

//Falconer, NY

const latitude = 42.11867;

const longitude = -79.19838;

export function getForecastWeather() {
  const weatherApi = fetch(
    `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=imperial&appid=${apiKey}`,
  ).then((res) => {
    return api.processServerResponse(res);
  });

  return weatherApi;
}

export function parseWeatherData(data) {
  const weatherData = {};

  weatherData["temperatureF"] = Math.ceil(data.main.temp);

  weatherData["temperatureC"] = Math.ceil(((data.main.temp - 32) * 5) / 9);

  weatherData["location"] = `${data.name}, ${data.sys.country}`;

  weatherData["weatherType"] = data.weather[0].main.toLowerCase();

  weatherData["isDay"] = getIsDay(data);

  return weatherData;
}

function getIsDay(data) {
  const currentDate = Math.ceil(Date.now() / 1000);

  if (currentDate < data.sys.sunset && currentDate > data.sys.sunrise) {
    return true;
  }

  return false;
}
