import { useContext } from "react";
import CurrentTemperatureUnitContext from "../../contexts/CurrentTemperatureUnitContext";
import "./ClothesSection.css";
import ItemCard from "../ItemCard/ItemCard";

function ClothesSection({ weatherTemp, cards, onCardLike, onCardSelect }) {
    const { currentTemperatureUnit } = useContext(CurrentTemperatureUnitContext);

    return (
        <section className="cardlist">
            {weatherTemp && (
                <p className="cardlist__header">
                    Today is {weatherTemp}°{currentTemperatureUnit} / You may want to
                    wear:
                </p>
            )}
            <ul className="cardlist__container">
                {cards.map((item) => {
                    return (
                        <li key={item._id}>
                            <ItemCard
                                item={item}
                                onCardLike={onCardLike}
                                onCardSelect={onCardSelect}
                            />
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}

export default ClothesSection;
